import React from 'react'
import styled from 'styled-components'
import { colors, breakpointsDown, fonts } from '../styles/constants'
import { OrderContext } from '../utils/context/orderContext'
import Divider, { DividerColor } from './Divider'
import CartItem from './CartItem'
import { LinkButton } from './Button'

import CrossAsset from '../images/cross.svg'
import { deliveryEnabled } from '../utils/pageConstants'
import { Types } from '../utils/context/reducers'

const CartWrapper = styled.div<CartWrapperProps>`
  position: fixed;
  right: ${({ isOpen }) => (isOpen ? 0 : '-28rem')};
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  z-index: 2000;
  background-color: white;
  width: 28rem;
  transition: right 0.2s ease-out;

  @media ${breakpointsDown.tablet} {
    width: 100%;
    right: ${({ isOpen }) => (isOpen ? 0 : '-100%')};
  }
`

const HeaderWrapper = styled.div`
  margin: 3rem 2.5rem 2rem 2.5rem;
  color: ${colors.darkPink};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const ContinueWrapper = styled.div`
  padding: 1rem 2.5rem 3rem 2.5rem;
  width: 100%;
  color: ${colors.darkPink};
  display: flex;
  flex-direction: column;
`

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.2rem;
`

const Price = styled.h4`
  font-weight: normal;
  text-align: right;
`

const Cross = styled.button`
  background: none;
  border: none;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  img {
    margin-bottom: 0.3rem;
  }
`

const EmptyCart = styled.button`
  color: ${colors.darkBlue};
  margin-top: 1rem;
  background: transparent;
  border: none;
  font-family: ${fonts.brown};
  text-decoration: underline;
  cursor: pointer;
`

interface CartWrapperProps {
  isOpen: boolean
}

interface CartProps {
  isOpen: boolean
  setClosed: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const Cart: React.FC<CartProps> = ({ isOpen, setClosed }) => {
  const { state, dispatch } = React.useContext(OrderContext)
  const [total, setTotal] = React.useState(0)

  React.useEffect(() => {
    const totalsArray = Object.values(state.items).map(
      ({ item, amount }) => item.price * amount,
    )
    const newTotal = totalsArray.reduce((a, b) => a + b, 0)
    setTotal(newTotal)
  })

  return (
    <CartWrapper isOpen={isOpen}>
      <HeaderWrapper>
        <h3>Beställning</h3>
        <Cross onClick={setClosed}>
          <img src={CrossAsset} alt="Close" />
        </Cross>
      </HeaderWrapper>
      <Divider assetColor={DividerColor.Blue} />
      <div>
        {Object.values(state.items).map(({ item, amount }) => (
          <CartItem key={item.id} item={item} amount={amount} />
        ))}
      </div>
      <ContinueWrapper>
        <PriceWrapper>
          <h4>Totalt</h4>
          <Price>{total} kr</Price>
        </PriceWrapper>
        {total > 0 && (
          <>
            <LinkButton
              link="/checkout"
              text={deliveryEnabled ? 'Välj leveransmetod' : 'Fortsätt'}
              wide={true}
            />
            <EmptyCart
              onClick={(_e) =>
                dispatch({
                  type: Types.Purge,
                })
              }
            >
              Töm beställning
            </EmptyCart>
          </>
        )}
      </ContinueWrapper>
    </CartWrapper>
  )
}

export default Cart
