import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-scroll'
import { AnimatePresence, motion } from 'framer-motion'
import { spacings, breakpointsDown } from '../styles/constants'
import LogoAsset from '../images/logo.svg'
import { useDocumentScroll } from '../utils/hooks/useDocumentScroll'
import { OrderContext } from '../utils/context/orderContext'
import Notification from './Notification'
import Cart from './Cart'
import { Types } from '../utils/context/reducers'

interface HeaderProps {
  isCollapsed: boolean
}

const HeaderContainer = styled.div<HeaderProps>`
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s ease-out, padding 0.2s ease-out;
  background-color: ${({ isCollapsed }) =>
    isCollapsed ? 'white' : 'transparent'};
  padding: ${({ isCollapsed }) => (isCollapsed ? '1rem' : '3rem')}
    ${spacings.horizontal};

  @media ${breakpointsDown.desktop} {
    padding-left: ${spacings.horizontalMobile};
    padding-right: ${spacings.horizontalMobile};
  }

  @media ${breakpointsDown.middle} {
    padding-top: ${({ isCollapsed }) => (isCollapsed ? '1rem' : '2rem')};
    padding-bottom: ${({ isCollapsed }) => (isCollapsed ? '1rem' : '2rem')};
  }
`

const Items = styled.div`
  width: calc(50% - 4.5rem);
  display: flex;
  flex-direction: row;
  @media ${breakpointsDown.tablet} {
    display: none;
  }
`

const ItemsRight = styled(Items)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  @media ${breakpointsDown.tablet} {
    display: flex;
    width: 50%;
    font-size: 0.8rem;
  }
`

const NotificationsWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 30px;
  text-align: right;
  display: flex;
  flex-direction: column-reverse;
`

const Item = styled(motion.div)`
  margin: 0 3rem 0 0;
  text-decoration: none;
  color: inherit;
  @media ${breakpointsDown.tablet} {
    display: none;
  }
`

const StyledLink = styled(Link)`
  cursor: pointer;
`

const ItemRight = styled(motion.div)`
  margin: 0;
  cursor: pointer;
`

const Logo = styled(motion.img)<HeaderProps>`
  transition: width 0.2s ease-out;
  width: ${({ isCollapsed }) => (isCollapsed ? '6.5rem' : '9rem')};

  @media ${breakpointsDown.middle} {
    width: ${({ isCollapsed }) => (isCollapsed ? '4.5rem' : '6rem')};
  }
`

const Header: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = React.useState(false)
  const [isCartOpen, setIsCartOpen] = React.useState(false)
  const { state, dispatch } = React.useContext(OrderContext)

  const [totalAmount, setTotalAmount] = React.useState(0)

  React.useEffect(() => {
    const totalItems = Object.values(state.items)
      .map((item) => item.amount)
      .reduce((a, b) => a + b, 0)
    setTotalAmount(totalItems)
  })

  useDocumentScroll(({ currentScrollTop }) => {
    if (currentScrollTop > 30) {
      setIsCollapsed(true)
    } else {
      setIsCollapsed(false)
    }
  })

  return (
    <>
      <HeaderContainer isCollapsed={isCollapsed}>
        <Items>
          <Item
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            <StyledLink to="order" smooth={true} offset={-30} duration={500}>
              Beställ
            </StyledLink>
          </Item>
          <Item
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            <StyledLink to="about" smooth={true} offset={-30} duration={500}>
              Om Ellas Café
            </StyledLink>
          </Item>
        </Items>
        <Logo
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          isCollapsed={isCollapsed}
          alt="Ellas Café logo"
          src={LogoAsset}
        />
        <ItemsRight>
          <ItemRight
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6 }}
            onClick={() => setIsCartOpen(!isCartOpen)}
          >
            Beställing ({totalAmount})
          </ItemRight>
          <NotificationsWrapper>
            <AnimatePresence>
              {state.newItems.map((item) => (
                <Notification
                  key={item.id}
                  text={item.name}
                  deleteAction={() => {
                    dispatch({
                      type: Types.DeleteNewItem,
                      id: item.id,
                    })
                  }}
                />
              ))}
            </AnimatePresence>
          </NotificationsWrapper>
        </ItemsRight>
      </HeaderContainer>
      <Cart isOpen={isCartOpen} setClosed={() => setIsCartOpen(false)} />
    </>
  )
}

export default Header
