import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import Header from '../components/Header'
import Hero from '../components/index/Hero'
import Order from '../components/index/Order'
import About from '../components/index/About'
import Footer from '../components/Footer'
import { IndexProps } from '../utils/types/IndexProps'

import { FikaItem } from '../utils/types/FikaItem'

import CinnamonBunImage from '../images/cinnamon.png'
import SugarCakeImage from '../images/sugarcake.png'
import styled from 'styled-components'
import { colors } from '../styles/constants'

/*const fikaItems: FikaItem[] = [
  {
    image: CinnamonBunImage,
    name: 'Kanelbulle',
    description: 'Klassikern, nybakad med kanelfyllning.',
    price: 20,
    unitText: 'st',
    id: 'kanelbulle',
  },
  {
    image: SugarCakeImage,
    name: 'Sockerkaka',
    description: 'Bästa bakverket. God till kaffet!',
    price: 15,
    unitText: 'bit',
    id: 'sockerkaka1',
  },
  {
    image: SugarCakeImage,
    name: 'Sockerkaka',
    description: 'Bästa bakverket. God till kaffet!',
    price: 15,
    unitText: 'bit',
    id: 'sockerkaka2',
  },
]*/

const InfoBanner = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 1rem;
  background-color: ${colors.darkPink};
  color: white;
  text-align: center;
`

const IndexPage: React.FC<IndexProps> = ({ data }: IndexProps) => {
  const [fikaItems, setFikaItems] = React.useState<FikaItem[]>([])

  React.useEffect(() => {
    const items: FikaItem[] = data.fika.edges.map(
      ({
        node: {
          name,
          description: { description },
          unitText,
          price,
          id,
          image: {
            fixed: { src },
          },
        },
      }) => {
        return {
          name: name,
          description: description,
          price: price,
          unitText: unitText,
          id: id,
          image: src,
        }
      },
    )
    if (items !== fikaItems) {
      setFikaItems(items)
    }
  }, [])

  return (
    <Layout>
      <SEO title="Hem" />
      <Header />
      <Hero />
      <Order items={fikaItems} />
      <About />
      <Footer />
      <InfoBanner>
        <p>
          {/* Ellas Café är för närvarande stängt, men håll utkik när vi öppnar igen
          framåt slutet av juli/början av augusti! */}
          {/*Hej! Jag kommer ha öppet vissa helger i höst, så håll utkik😋*/}
          Ellas Café håller öppet helgen den 10-11 juni!
        </p>
      </InfoBanner>
    </Layout>
  )
}

export default IndexPage

export const fikaQuery = graphql`
  {
    fika: allContentfulFika {
      edges {
        node {
          name
          description {
            description
          }
          unitText
          price
          id
          image {
            fixed(width: 800) {
              src
            }
          }
        }
      }
    }
  }
`
