import React from 'react'
import styled from 'styled-components'
import { Element } from 'react-scroll'
import { spacings, colors, breakpointsDown } from '../../styles/constants'

import Fika from './Fika'

import { FikaItem } from '../../utils/types/FikaItem'
import { deliveryEnabled } from '../../utils/pageConstants'

const OrderWrapper = styled.div`
  background-color: ${colors.lightPink};
  width: 100%;
  padding: ${spacings.vertical} ${spacings.horizontal};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${breakpointsDown.wide} {
    padding: ${spacings.vertical} ${spacings.horizontalMobile};
  }
`

const Header = styled.div`
  color: ${colors.darkPink};
  margin-bottom: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const Separator = styled.div`
  width: 3.6rem;
  height: 0.32rem;
  background-color: ${colors.darkPink};
`

const HeaderText = styled.h2`
  margin: 2rem 0 1.4rem 0;
`

const Info = styled.p`
  max-width: 35rem;
  text-align: center;
`

const FikaItems = styled.div`
  margin-top: 2.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

interface OrderProps {
  items: FikaItem[]
}

const Order: React.FC<OrderProps> = ({ items }) => {
  return (
    <Element name="order">
      <OrderWrapper>
        <Header>
          <Separator />
          <HeaderText>Beställ</HeaderText>
          <Separator />
        </Header>
        <Info>
          När du har valt vilket fika du vill ha klickar du på Beställning uppe
          i högra hörnet, där du går vidare och kan välja vilken tid du vill
          {deliveryEnabled ? ' ha leverans/upphämtning' : ' hämta upp fikat.'}.
          Betalning sker med Swish vid överlämning.
        </Info>
        <FikaItems>
          {items.map((item) => (
            <Fika key={item.id} item={item} />
          ))}
        </FikaItems>
      </OrderWrapper>
    </Element>
  )
}

export default Order
