import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/constants'

import PlusAsset from '../../images/plus.svg'
import MinusAsset from '../../images/minus.svg'

const Outline = styled.div`
  border: 1.3px solid ${colors.darkBlue};
  border-radius: 10px;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  justify-content: center;
`

const Number = styled.div`
  text-align: center;
  font-size: 1.3rem;
  margin-top: 0.5rem;
  flex: 1;
`

const Divider = styled.div`
  width: 1px;
  height: 2.2rem;
  background-color: ${colors.darkBlue};
`

const IncrementButton = styled.button<IncrementProps>`
  width: 2.5rem;
  padding-top: 0.2rem;
  background: none;
  border: none;
  transition: background-color 0.3s;
  opacity: ${(props) => (props.disabled ? '40%' : '100%')};

  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.disabled ? 'transparent' : '#BAD7A5'};
  }

  &:active {
    background-color: ${(props) =>
      props.disabled ? 'transparent' : '#90AA7D'};
  }

  &:focus {
    outline: none;
  }
`

interface IncrementProps {
  disabled: boolean
}

interface NumberPickerProps {
  addHandler: (event: React.MouseEvent<HTMLButtonElement>) => void
  removeHandler: (event: React.MouseEvent<HTMLButtonElement>) => void
  count: number
}

const NumberPicker: React.FC<NumberPickerProps> = ({
  addHandler,
  removeHandler,
  count,
}) => {
  //const [count, setCount] = React.useState(0)

  return (
    <Outline>
      <IncrementButton disabled={count <= 0} onClick={removeHandler}>
        <img src={MinusAsset} />
      </IncrementButton>
      <Divider />
      <Number>{count}</Number>
      <Divider />
      <IncrementButton disabled={false} onClick={addHandler}>
        <img src={PlusAsset} />
      </IncrementButton>
    </Outline>
  )
}

export default NumberPicker
