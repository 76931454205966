import React from 'react'
import styled from 'styled-components'
import { Element } from 'react-scroll'
import { spacings, breakpointsDown } from '../../styles/constants'

import OrnamentAsset from '../../images/ornament-rect.svg'
import EllaAsset from '../../images/ella.jpg'

const AboutWrapper = styled.div`
  background-color: white;
  width: 100%;
  padding: ${spacings.vertical} ${spacings.horizontal};
  display: flex;
  flex-direction: column;

  @media ${breakpointsDown.wide} {
    padding: ${spacings.vertical} ${spacings.horizontalMobile};
    align-items: center;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${spacings.vertical};

  h2 {
    margin: 0 2rem;
    text-align: center;
  }

  @media ${breakpointsDown.tablet} {
    margin-bottom: 4rem;
  }
`

const Ornament = styled.img`
  margin-bottom: 0.5rem;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    max-width: 40rem;
    line-height: 160%;
  }

  @media ${breakpointsDown.tablet} {
    flex-direction: column;
  }
`

const Ella = styled.img`
  max-width: 29rem;
  object-fit: cover;
  margin-right: 4rem;

  @media ${breakpointsDown.desktop} {
    width: 40vw;
  }

  @media ${breakpointsDown.tablet} {
    width: 100%;
    max-width: 1000px;
    margin-right: 0;
    height: 21rem;
    margin-bottom: 2.5rem;
  }
`

const About: React.FC = () => (
  <Element name="about">
    <AboutWrapper>
      <TitleWrapper>
        <Ornament src={OrnamentAsset} />
        <h2>Om Ellas Café</h2>
        <Ornament src={OrnamentAsset} />
      </TitleWrapper>
      <ContentWrapper>
        <Ella src={EllaAsset} alt="Bild på Ella" />
        <p>
          Våren 2020 grusades mina sommarplaner liksom många andras. Jag hade
          planerat att jobba men nu fanns det inga platser att få för en som går
          ettan på gymnasiet. Under ett nostalgiskt samtal med familjen runt
          matbordet föddes dock idén om att sälja fika till de som bor nära på
          ett coronasäkert sätt. Vi hade nämligen diskuterat hur
          entreprenöriella vi var som barn då vi under sommarlovet arrangerade
          sommarcafé på garageuppfarten, så varför inte göra något liknande
          igen? Jag får användning av mitt intresse för bakning och ett riktigt
          kul projekt att starta upp, som även fortsätter i år. Förhoppningsvis
          kan jag också sprida en del glädje med det nybakade fikat!
          <br />
          <br />/ Ella
        </p>
      </ContentWrapper>
    </AboutWrapper>
  </Element>
)

export default About
