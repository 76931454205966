import React from 'react'
import styled from 'styled-components'
import { spacings, colors, breakpointsDown } from '../../styles/constants'
import BorderAsset from './border-transparent.svg'
import LeftImageAsset from '../../images/left-hero.png'
import RightImageAsset from '../../images/right-hero.png'
import { deliveryEnabled } from '../../utils/pageConstants'

const HeroWrapper = styled.div`
  background-color: ${colors.lightBlue};
  width: 100%;
  padding: 12rem ${spacings.horizontal} ${spacings.vertical}
    ${spacings.horizontal};
  display: flex;
  justify-content: center;

  @media ${breakpointsDown.desktop} {
    padding: 12rem ${spacings.horizontalMobile} ${spacings.vertical}
      ${spacings.horizontalMobile};
  }

  @media ${breakpointsDown.middle} {
    padding-bottom: calc(${spacings.vertical} + 8rem);
    padding-top: 8rem;
  }
`

const HeroCard = styled.div`
  background-color: ${colors.darkBlue};
  padding: 1rem 2rem 0.6rem 2rem;
  color: ${colors.lightBlue};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-style: solid;
  background-clip: padding-box;
  border-width: 40px;
  border-image: url(${BorderAsset}) 25% round;
  z-index: 999;
  position: relative;

  &:after {
    /* Hack to avoid white line */
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0px;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    background-color: ${colors.darkBlue};
  }

  @media ${breakpointsDown.tablet} {
    padding: 1rem 1.2rem 0.6rem 1.2rem;
  }

  @media ${breakpointsDown.middle} {
    padding: 1rem 0.6rem 0.6rem 0.6rem;
  }
`

const Paragraph = styled.p`
  max-width: 35rem;
  margin-top: 1rem;
`

const Wrapper = styled.div`
  position: relative;
`

const ImageWrapper = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: -8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 998;

  @media ${breakpointsDown.middle} {
    bottom: -5rem;
  }
`

const LeftImage = styled.img`
  max-width: 28rem;
  width: 30vw;
  min-width: 15rem;
  object-fit: contain;
  height: 100%;
`

const RightImage = styled.img`
  max-width: 23rem;
  width: 28vw;
  min-width: 12rem;
  object-fit: contain;
  height: 100%;
`

const Hero: React.FC = () => (
  <Wrapper>
    <HeroWrapper>
      <HeroCard>
        <h1>Fika nära dig</h1>
        <Paragraph>
          Ellas Café är ditt kvartersfik i Bromma. Här kan du som bor i närheten
          beställa fika och hämta upp det
          {/*Under COVID-19 tvingas många stanna hemma. Därför har jag startat
          Ellas Café, där du som bor i Bromma kan beställa fika och hämta upp
          det*/}
          {deliveryEnabled
            ? ' eller få det hemlevererat'
            : ' vid Islandsvägen i Norra Ängby'}
          .
          <br /> Hoppas det smakar! / Ella
        </Paragraph>
      </HeroCard>
    </HeroWrapper>
    <ImageWrapper>
      <LeftImage src={LeftImageAsset} />
      <RightImage src={RightImageAsset} />
    </ImageWrapper>
  </Wrapper>
)

export default Hero
