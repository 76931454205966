import React from 'react'
import styled from 'styled-components'
import { colors, breakpointsDown } from '../../styles/constants'
import { OrderContext } from '../../utils/context/orderContext'

import NumberPicker from './NumberPicker'

import { FikaItem } from '../../utils/types/FikaItem'
import { Types } from '../../utils/context/reducers'

const ItemWrapper = styled.div`
  background-color: ${colors.lightGreen};
  margin: 0.8rem;
  width: calc(50% - 1.6rem);
  display: flex;
  flex-direction: row;

  @media ${breakpointsDown.wide} {
    flex-direction: column;
  }

  @media ${breakpointsDown.middle} {
    width: 100%;
    margin: 0.8rem 0;
  }
`

const FikaImage = styled.img`
  object-fit: cover;
  width: 50%;
  height: 18rem;

  @media ${breakpointsDown.wide} {
    width 100%;
    height: 12rem;
  }
`

const EdgeWrapper = styled.div`
  width: 50%;
  padding: 1rem;
  flex: 1;

  @media ${breakpointsDown.wide} {
    width 100%;
  }
`

const InfoEdge = styled.div`
  height: 100%;
  border: 3px solid ${colors.lightPink};
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    margin-bottom: 0.2rem;
  }
`

const Price = styled.p`
  font-size: 1.3rem;
  margin: 1rem 0 0.3rem 0;
`

const NumberPickerWrapper = styled.div`
  max-width: 13rem;
`

interface FikaProps {
  item: FikaItem
}

const Fika: React.FC<FikaProps> = ({
  item,
  item: { name, image, description, price, unitText, id },
}) => {
  const { state, dispatch } = React.useContext(OrderContext)

  return (
    <ItemWrapper>
      <FikaImage src={image} />
      <EdgeWrapper>
        <InfoEdge>
          <h3>{name}</h3>
          <p>{description}</p>
          <Price>{`${price} kr/${unitText}`}</Price>
          <NumberPickerWrapper>
            <NumberPicker
              count={state.items[id] ? state.items[id].amount : 0}
              addHandler={() => {
                dispatch({
                  type: Types.Add,
                  create: item,
                })
              }}
              removeHandler={() => {
                dispatch({
                  type: Types.Delete,
                  id: id,
                })
              }}
            />
          </NumberPickerWrapper>
        </InfoEdge>
      </EdgeWrapper>
    </ItemWrapper>
  )
}

export default Fika
