import React from 'react'
import styled from 'styled-components'
import { spacings, colors, breakpointsDown } from '../styles/constants'
import LogoAsset from '../images/logo.svg'

const FooterContainer = styled.div`
  background-color: ${colors.lightBlue};
  width: 100%;
  padding: ${spacings.vertical} ${spacings.horizontal};
  display: flex;
  flex-direction: column;

  @media ${breakpointsDown.wide} {
    padding: ${spacings.vertical} ${spacings.horizontalMobile};
  }
`

const Logo = styled.img`
  width: 9rem;
  margin-bottom: 2.5rem;
`

const Footer: React.FC = () => (
  <FooterContainer>
    <Logo alt="Ellas Café logo" src={LogoAsset} />
    <p>Startat 2020 i Bromma</p>
  </FooterContainer>
)

export default Footer
