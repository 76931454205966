import React from 'react'
import styled from 'styled-components'
import { colors, breakpointsDown } from '../styles/constants'
import Divider, { DividerColor } from './Divider'

import { FikaItem } from '../utils/types/FikaItem'

const ContentWrapper = styled.div`
  padding: 1rem 2.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SquareImage = styled.img`
  width: 7rem;
  height: 7rem;
  object-fit: cover;
  margin-right: 1.5rem;
`

const InfoWrapper = styled.div`
  color: ${colors.darkBlue};
`

interface CartItemProps {
  item: FikaItem
  amount: number
}

const CartItem: React.FC<CartItemProps> = ({
  amount,
  item: { image, name, price },
}) => {
  return (
    <div>
      <div />
      <ContentWrapper>
        <SquareImage src={image} alt={name} />
        <div>
          <div />
          <div>
            <InfoWrapper>
              <h4>{name}</h4>
              <p>
                {amount} st. à {price} kr
              </p>
              <br />
              <p>{amount * price} kr</p>
            </InfoWrapper>
          </div>
        </div>
      </ContentWrapper>
      <Divider assetColor={DividerColor.Blue} />
    </div>
  )
}

export default CartItem
