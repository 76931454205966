import * as React from 'react'
import { throttle } from 'lodash'

export const useDocumentScroll = (
  callback: (scrollStatus: {
    previousScrollTop: number
    currentScrollTop: number
    scrollDirection: 'down' | 'up'
  }) => void,
): void => {
  const [, setScrollTop] = React.useState(0)
  let previousScrollTop = 0

  const onScroll = () => {
    const element = document.documentElement || document.body
    const currentScrollTop = element.scrollTop

    setScrollTop((previousValue) => {
      previousScrollTop = previousValue
      return currentScrollTop
    })

    const scrollDirection =
      currentScrollTop >= previousScrollTop ? 'down' : 'up'

    callback({ previousScrollTop, currentScrollTop, scrollDirection })
  }

  const onScrollThrottled = throttle(onScroll, 200)

  React.useEffect(() => {
    window.addEventListener('scroll', onScrollThrottled)
    return () => window.removeEventListener('scroll', onScrollThrottled)
  }, [])
}
