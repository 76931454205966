import React from 'react'
import { motion } from 'framer-motion'

interface NotificationProps {
  text: string
  deleteAction: () => void
}

const Notification: React.FC<NotificationProps> = ({ text, deleteAction }) => {
  React.useEffect(() => {
    const timer = setTimeout(deleteAction, 2000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      transition={{
        type: 'spring',
        stiffness: 500,
        damping: 40,
      }}
      positionTransition
    >
      <p>+ {text}</p>
    </motion.div>
  )
}

export default Notification
